var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Asset Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Asset Number","required":"","outlined":"","dense":""},model:{value:(_vm.cameras.asset_number),callback:function ($$v) {_vm.$set(_vm.cameras, "asset_number", $$v)},expression:"cameras.asset_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Serial Number","required":"","outlined":"","dense":""},model:{value:(_vm.cameras.serial_number),callback:function ($$v) {_vm.$set(_vm.cameras, "serial_number", $$v)},expression:"cameras.serial_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Type","dense":"","required":"","outlined":""},model:{value:(_vm.cameras.type),callback:function ($$v) {_vm.$set(_vm.cameras, "type", $$v)},expression:"cameras.type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.camera_status,"error-messages":errors,"item-text":"name","item-value":"name","menu-props":"auto","label":"Status","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiHeartPulse},model:{value:(_vm.cameras.status),callback:function ($$v) {_vm.$set(_vm.cameras, "status", $$v)},expression:"cameras.status"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"User Manual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"User Manual","required":"","outlined":"","dense":""},model:{value:(_vm.cameras.user_manual),callback:function ($$v) {_vm.$set(_vm.cameras, "user_manual", $$v)},expression:"cameras.user_manual"}})]}}])})],1)],1),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Save ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }